import PageMeta from "components/layout/pageMeta";
import Menu from "components/layout/menu";
import Footer from "components/layout/footer";
import Header from "components/layout/header";
import { GetStaticProps } from "next";
import { PreviewRequirements } from "../preview";
import { client } from "helpers/sanity";
import { globalQueries, portableTextQuery } from "../../helpers/sanityQueries";
import PortableText from "../../components/portableText/portableText";
import Image from "../../components/media/image";

const TitleBlock = ({ contentBlock, showHeader, index }) => {
  let imageModifierClassNames = "";

  if (contentBlock.hideImageOnMobile)
    imageModifierClassNames += " hide-on-mobile";

  if (contentBlock.absolutePositionImage)
    imageModifierClassNames += " absolute-position";

  return (
    <section key={contentBlock._key} className="o-wrapper">
      <div className="c-title-block u-padding-top-bottom">
        <div className="c-title-block__text">
          {showHeader ||
            (!showHeader && index != 0 && <h2>{contentBlock.title}</h2>)}
          {!showHeader && index == 0 && <h1>{contentBlock.title}</h1>}
          <PortableText blocks={contentBlock.lede} />
        </div>
        {contentBlock.image && (
          <Image
            className={"c-title-block__image " + imageModifierClassNames}
            interceptBuilder={builder => builder.width(450)}
            image={contentBlock.image}
            alt={contentBlock.image.alt}
          />
        )}
      </div>
    </section>
  );
};

const TextBlock = ({ contentBlock }) => {
  const {
    _key,
    portableText,
    backgroundColor,
    image,
    textRatio,
    imageLocation,
  } = contentBlock;

  const backgroundClassName =
    backgroundColor == "lightYellow"
      ? "u-bg--light-yellow"
      : backgroundColor == "lightGrey"
        ? "u-bg--lightgrey"
        : "";

  let layoutClassNames = "";
  if (image?.asset) {
    layoutClassNames += "c-text-block__with-image ";
    // TODO: Make less hairy
    layoutClassNames +=
      textRatio == "third"
        ? imageLocation == "right"
          ? "third"
          : "two-thirds"
        : textRatio == "two-thirds"
          ? imageLocation == "right"
            ? "two-thirds"
            : "third"
          : "";

    if (imageLocation == "left") layoutClassNames += " image-first";
  }

  return (
    <section
      key={_key}
      className={"u-padding-top-bottom " + backgroundClassName}
    >
      <div className={"o-wrapper " + layoutClassNames}>
        <PortableText blocks={portableText} className="text" />
        {image?.asset && (
          <div className="image">
            <Image
              interceptBuilder={builder => builder.width(800)}
              image={image}
              alt={image.alt}
            />
          </div>
        )}
      </div>
    </section>
  );
};

const ColumnBlock = ({ contentBlock, previousBlock }) => {
  const { _key, columns, backgroundColor, cardRatio } = contentBlock;

  const backgroundClassName =
    backgroundColor == "lightYellow"
      ? "u-bg--light-yellow"
      : backgroundColor == "lightGrey"
        ? "u-bg--lightgrey"
        : "";

  const getLayout = cardRatio => {
    if (cardRatio === "fifty-fifty") return "fifty-fifty";

    if (cardRatio === "first-double") return "first-double";

    if (cardRatio == "last-double") return "second-double";

    return "";
  };

  const padding =
    previousBlock &&
    previousBlock._type == contentBlock._type &&
    previousBlock.backgroundColor == contentBlock.backgroundColor
      ? "u-padding-bottom"
      : "u-padding-top-bottom";

  return (
    <section key={_key} className={padding + " " + backgroundClassName}>
      <div className={"o-wrapper c-column-blocks " + getLayout(cardRatio)}>
        {columns &&
          columns.map(column => {
            return (
              <div key={column._key} className="c-column-block">
                <h3 className="">{column.title}</h3>
                <PortableText blocks={column.portableText} />
                {column.image && (
                  <Image
                    className=""
                    interceptBuilder={builder => builder.width(600).height(400)}
                    image={column.image}
                    alt={column.image.alt}
                  />
                )}
              </div>
            );
          })}
      </div>
    </section>
  );
};

const LandingPage = props => {
  const {
    doc,
    doc: {
      title = "Ingen tittel",
      lede = "",
      headerColor,
      showMenu = false,
      showFooter = true,
      showHeader = true,
      contentBlocks,
      footerLeft,
      footerRight,
      footerTitle,
      illustration,
    },
    menu,
    contact,
    settings,
  } = props;

  return (
    <>
      <PageMeta doc={doc} settings={settings} />
      {showMenu && <Menu menu={menu} title={title} />}
      {!showMenu && <br />}

      {showHeader && (
        <Header
          type={headerColor}
          title={title}
          lede={lede}
          slim={true}
          illustration={illustration}
        />
      )}

      <article className="u-padding-top-bottom--large">
        {contentBlocks &&
          contentBlocks.map((contentBlock, index, contentBlocks) => {
            if (contentBlock._type == "benefitsChecklist") {
              return (
                <section
                  key={contentBlock._key}
                  className="o-wrapper u-padding-top-bottom"
                >
                  <div className="c-benefits c-benefits__yellow">
                    <h2 key={contentBlock._key}>{contentBlock.headline}</h2>
                    {contentBlock.bullets && (
                      <ul>
                        {contentBlock.bullets.map((bullet, index) => {
                          return <li key={"bullet-" + index}>{bullet}</li>;
                        })}
                      </ul>
                    )}
                  </div>
                </section>
              );
            }

            if (contentBlock._type == "textBlock") {
              return (
                <TextBlock
                  contentBlock={contentBlock}
                  key={contentBlock._key}
                />
              );
            }

            if (contentBlock._type == "columnBlock") {
              return (
                <ColumnBlock
                  contentBlock={contentBlock}
                  previousBlock={contentBlocks[index - 1]}
                  key={contentBlock._key}
                />
              );
            }

            if (contentBlock._type == "titleBlock") {
              return (
                <TitleBlock
                  contentBlock={contentBlock}
                  index={index}
                  showHeader={showHeader}
                  key={contentBlock._key}
                />
              );
            }

            return (
              <section
                key={contentBlock._key}
                className="o-wrapper u-padding-top-bottom"
              >
                <h1>
                  En uhåndtert <code>{contentBlock._type}</code>-blokk
                </h1>
              </section>
            );
          })}
      </article>

      {showFooter && <Footer contact={contact} settings={settings} />}

      {!showFooter && (
        <footer className="c-footer">
          <div className="o-wrapper u-padding-top--larger u-padding-bottom--large">
            <h3>{footerTitle}</h3>
            <div className="o-grid ">
              <div className="o-grid__item o-grid__item--two-thirds">
                <PortableText blocks={footerLeft} />
              </div>
              <div className="o-grid__item o-grid__item--third">
                <PortableText blocks={footerRight} />
              </div>
            </div>
          </div>
        </footer>
      )}
    </>
  );
};

export const getStaticProps: GetStaticProps<PreviewRequirements> = async ({
  params,
}) => {
  if (!params) {
    throw new Error("Missing params, should never happen.");
  }

  const { slug = "" } = params;
  const groqParams = { slug };
  const groqQuery = {
    doc: `*[_type== "landingPage" && slug.current == $slug && !(_id in path("drafts.*"))][0]
    {
      ...,
      illustration{ ..., asset-> },
      contentBlocks[] {
        ...,
        ${portableTextQuery},
        image{ ..., asset-> }
      }
    }`,
    ...globalQueries,
  };

  const result = await client.fetch(groqQuery, groqParams);
  if (!result || !result.doc || Object.keys(result.doc).length === 0) {
    return { notFound: true, props: { groqQuery, groqParams } };
  }

  return { props: { ...result, groqQuery, groqParams } };
};

export async function getStaticPaths() {
  const docs = await client.fetch(
    `*[_type == "landingPage" && !(_id in path('drafts.**'))][0...10000]{slug}`
  );
  const paths = docs.map(doc => ({ params: { slug: doc.slug.current } }));
  return { paths, fallback: "blocking" };
}

export default LandingPage;
